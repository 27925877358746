// src/index.js or src/index.tsx
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ImageChanger from "./ImageChanger";
import Navbar from "./Navbar";

export default function Header() {
  return (
    <header className="flex w-screen flex-wrap items-center justify-around relative p-4 sm:p-2">
      <h1 className="flex text-[33px] font-[Poppins] text-white drop-shadow-lg">
        Moye Moye
      </h1>
        <Navbar />
      <div className="rounded-full flex drop-shadow-lg">
        <ImageChanger />
      </div>
    </header>
  );
}
