import Footer from "./Footer";
import ImageChanger from "./ImageChanger";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <header className="flex bg-white sticky w-screen flex-wrap items-center justify-around top-0 z-20 h-[60px] lg:static ">
        <h1 className="flex text-[33px] font-[Poppins] text-[#295943] drop-shadow-lg">
          Moye Moye
        </h1>
        <nav className="flex items-center mt-3">
          <ul className="flex drop-shadow-lg sm:flex-wrap sm:justify-around sm:pl-0">
            <li className="pl-[20px] pr-[20px]">
              <Link
                to="/Home"
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
              >
                Home
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="/Discover"
              >
                Discover
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="#"
              >
                Services
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="#"
              >
                News
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="/About"
              >
                About Us
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="#"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <div className="rounded-full flex drop-shadow-lg">
          <ImageChanger />
        </div>
      </header>
      <div className="w-screen h-[300px]">
        <img
          className="h-[100%] w-[100%] object-cover -z-1"
          src="https://cdn.pixabay.com/photo/2012/08/27/14/19/mountains-55067_1280.png"
          alt="loda"
        />
        <h2 className="relative text-white text-[33px] w-[15%] left-[50px] z-5 top-[-100px] font-bold font[Poppins]">
          About Us
        </h2>
      </div>
      <section className="w-screen h-auto">
        <div className="mx-auto text-center w-[30%] mt-[60px] text-[#3D3E48] font-sans xs:w-[70%]">
          <h2 className="font-bold text-[27px]">Why Choose Us?</h2>
          <p className="text-base">
            These popular destinations have a lot to offer.
          </p>
        </div>
        <div className="mt-[70px] flex align-center justify-between xs:flex-col">
          <div className="text-center flex justify-center flex-col">
            <img
              className="w-[128px] h-[128px] mx-auto"
              src="./images/bi-shield-check.svg"
              alt=""
            />
            <h2 className="text-[#3D3E48] text-[23px] pt-3">
              Best Price gurantee
            </h2>
            <p className=" text-wrap w-[80%] mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </p>
          </div>
          <div>
            <div className="text-center flex justify-center flex-col">
              <img
                className="w-[128px] h-[128px] mx-auto"
                src="./images/calendar.svg"
                alt=""
              />
              <h2 className="text-[#3D3E48] text-[23px] pt-3">
                Best Price gurantee
              </h2>
              <p className=" text-wrap w-[80%] mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </div>
          </div>
          <div>
            <div className="text-center flex justify-center flex-col">
              <img
                className="w-[128px] h-[128px] mx-auto"
                src="./images/customer-service.svg"
                alt=""
              />
              <h2 className="text-[#3D3E48] text-[23px] pt-3">
                Best Price gurantee
              </h2>
              <p className=" text-wrap w-[80%] mx-auto">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </div>
          </div>
        </div>
        <div className="flex w-screen h-auto mt-[100px] sm:text-sm">
          <div className="mt-[85px] text-wrap w-[70%] h-auto ml-[50px] font-[Poppins] relative -top-20 text-[#3D3E48] xs:w-[90%] lg:ml-2 xs:text-xs">
            <h2 className="font-bold text-[27px]">About MoyeMoye.com</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
              dictum et in feugiat. Platea in diam, est congue. Posuere sapien
              morbi augue ultrices. Et facilisi orci sollicitudin placerat lacus
              lacus nibh. Egestas semper massa viverra massa proin in morbi
              placerat. Pharetra nec, est non integer nisi, ut faucibus. Non, in
              nam sollicitudin vitae volutpat ac molestie. Turpis pellentesque
              sit pellentesque id cras lobortis tortor, blandit.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
              dictum et in feugiat. Platea in diam, est congue. Posuere sapien
              morbi augue ultrices. Et facilisi orci sollicitudin placerat lacus
              lacus nibh. Egestas semper massa viverra massa proin in morbi
              placerat. Pharetra nec, est non integer nisi, ut faucibus. Non, in
              nam sollicitudin vitae volutpat ac molestie. Turpis pellentesque
              sit pellentesque id cras lobortis tortor, blandit.
            </p>
          </div>
          <div className="pl-[10px] flex w-[380px] h-[500px] justify-self-end rounded-2xl overflow-clip">
            <img
              className=" w-[100%] h-[100%] object-cover"
              src="./images/rectangle-25.png"
              alt=""
            />
          </div>
        </div>
        <div className="font-[Poppins] flex flex-col text-[#3D3E48] relative mt-[50px] h-auto w-[95%] mx-auto">
          <h2 className="text-[27px] font-bold ml-10">Our Teams</h2>
          <p className="ml-10">Lorem ippu kutta pilla</p>
          <div className="mt-[50px] grid grid-cols-4 gap-2 w-[78%] mx-auto h-full sm:grid-cols-2 lg:gap-2 lg:gap-x-10">
            <div className="w-[100%] hover:scale-105 duration-300 ease-in-out">
              <img
                className="w-[200px] h-245px object-contain"
                src="./images/rectangle-30.png"
                alt=""
              />
              <h4 className="relative top-3 text-[19px]">Pervy Sage</h4>
              <p className="relative top-2 text-base text-[#3D3E48]">
                SIMP Chief
              </p>
            </div>
            <div className="w-[100%] hover:scale-105 duration-300 ease-in-out">
              <img
                className="w-[200px] h-245px"
                src="./images/rectangle-31.png"
                alt=""
              />
              <h4 className="relative top-3 text-[19px]">Lady Tsunade</h4>
              <p className="relative top-2 text-base text-[#3D3E48]">
                Ex-Hokage
              </p>
            </div>
            <div className="w-[100%] hover:scale-105 duration-300 ease-in-out">
              <img
                className="w-[200px] h-245px"
                src="./images/rectangle-32.png"
                alt=""
              />
              <h4 className="relative top-3 text-[19px]">Mundane Mann</h4>
              <p className="relative top-2 text-base text-[#3D3E48]">
                International Relations
              </p>
            </div>
            <div className="w-[100%] hover:scale-105 duration-300 ease-in-out">
              <img
                className="w-[200px] h-245px"
                src="./images/rectangle-33.png"
                alt=""
              />
              <h4 className="relative top-3 text-[19px]">Dr. Zhatka</h4>
              <p className="relative top-2 text-base text-[#3D3E48]">
                Organ Trafficer
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-[90%] relative h-auto mt-[100px] mx-auto xs:flex-col xs:justify-center xs:items-center">
          <div className="text-center xs:inline-block xs:items-center">
            <img
              className="w-[128px] h-[128px]"
              src="./images/ship.svg"
              alt=""
            />
            <h2 className="text-[#A8A7AB] text-[19px]">TRAVEL</h2>
          </div>
          <div className="text-center">
            <img
              className="w-[128px] h-[128px]"
              src="./images/building.svg"
              alt=""
            />
            <h2 className="text-[#A8A7AB] text-[19px]">CITY TOUR</h2>
          </div>
          <div className="text-center">
            <img
              className="w-[128px] h-[128px]"
              src="./images/island.svg"
              alt=""
            />
            <h2 className="text-[#A8A7AB] text-[19px]">ISLAND TOUR</h2>
          </div>
          <div className="text-center">
            <img
              className="w-[128px] h-[128px]"
              src="./images/maps.svg"
              alt=""
            />
            <h2 className="text-[#A8A7AB] text-[19px]">EXPLORE WORLD</h2>
          </div>
        </div>

        <div className="mt-[100px]">
          <Footer />
        </div>
      </section>
    </div>
  );
};

export default About;
