import React from "react";
import packagesData from "./Data";
import DiscoverL from "./DiscoverL";

export default function Map() {
  const packageElements = packagesData.map((item, index) => (
    <DiscoverL
      key={index}
      img={item.img}
      title={item.title}
      price={item.price}
    />
  ));

  return (
    <div className="grid w-[95%] mt-[50px] mx-auto grid-cols-3 grid-rows-2 gap-[20px] h-[650px] gap-y-8 xs:gap-y-1 sm:h-[450px] ">
      {packageElements}
    </div>
  );
}
