import { Link } from "react-router-dom";
import ImageChanger from "./ImageChanger";
import DiscoverG from "./DiscoverG";
import Map3 from "./Map3";
import Footer from "./Footer";

function Discover() {
  return (
    <div>
      <header className="flex bg-white sticky w-screen flex-wrap items-center justify-around top-0 z-50 h-[60px] lg:static xs:p-2">
        <h1 className="flex text-[33px] font-[Poppins] text-[#295943] drop-shadow-lg">
          Moye Moye
        </h1>
        <nav className="flex items-center mt-3">
          <ul className="flex drop-shadow-lg sm:flex-wrap sm:justify-around sm:pl-0">
            <li className="pl-[20px] pr-[20px]">
              <Link
                to="/Home"
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
              >
                Home
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="/Discover"
              >
                Discover
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="#"
              >
                Services
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="#"
              >
                News
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="/About"
              >
                About Us
              </Link>
            </li>
            <li className="pl-[20px] pr-[20px]">
              <Link
                className="no-underline text-[#3D3E48] font-[Poppins] text-lg font-bold"
                to="#"
              >
                Contact
              </Link>
            </li>
          </ul>
        </nav>
        <div className="rounded-full flex drop-shadow-lg">
          <ImageChanger />
        </div>
      </header>
      <div className="w-screen h-[300px]">
        <img
          className="h-[100%] w-[100%] object-cover -z-1"
          src="https://images.unsplash.com/photo-1464822759023-fed622ff2c3b?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bW91bnRhaW5zfGVufDB8fDB8fHww"
          alt="loda"
        />
        <h2 className="relative text-white text-[33px] w-[15%] left-[50px] z-5 top-[-100px] font-bold font[Poppins]">
          Discover
        </h2>
      </div>
      <section className="h-auto w-screen">
        <div className="flex justify-evenly w-screen lg:block">
          <div className="flex flex-wrap w-[60%] mt-[50px] sm:text-sm lg:text-sm lg:w-[90%] lg:mx-auto">
            <div className="flex items-center h-[80px] w-[80px] text-center text-[67px] mb-2 font-bold lg:text-[40px] ">
              D
            </div>
            <p className="font-sans text-black w-[680px]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
              aliquam, purus sit amet luctus venenatis, lectus magna fringilla
              urna, porttitor rhoncus dolor purus non enim praesent elementum
              facilisis leo, vel fringilla est ullamcorper eget nulla facilisi.
            </p>
            <p className=" text-black font-sans">
              enim lobortis scelerisque fermentum dui faucibus in ornare quam
              viverra orci sagittis eu volutpat odio facilisis mauris sit amet
              massa vitae tortor condimentum lacinia quis vel eros donec ac odio
              tempor orci dapibus ultrices in iaculis nunc sed augue lacus,
              viverra vitae congue eu, consequat ac felis donec et odio
              pellentesque diam volutpat commodo sed egestas egestas fringilla
              fau.
            </p>
          </div>
          <div className="w-[380px] h-[215px] overflow-clip mt-[50px] lg:mx-auto xs:w-[300px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d23089.99937145381!2d85.35570158941705!3d27.688730795084574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1704128948060!5m2!1sen!2snp"
              width="380"
              height="215"
              className="border-0 object-cover focus:outline-none xs:w-[300px]"
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              frameBorder={0}
            ></iframe>
          </div>
        </div>
        <div className="flex justify-between w-screen relative top-[60px] h-auto lg:block">
          <div className="w-[60%] mx-auto lg:w-[92%] lg:ml-0 sm:mx-auto sm:text-sm">
            <div className="rounded-lg overflow-clip w-full h-[300px]">
              <img
                className="w-full h-full object-cover"
                src="https://www.outlooktravelmag.com/media/bali-1-1679062958.profileImage.2x-scaled.webp"
                alt=""
              />
            </div>
            <h2 className=" text-[27px] text-[#3D3E48] mt-[20px] capitalize">
              Memorable Festivals On Bali Beach
            </h2>
            <p className="text-[#3D3E48] ">
              <i className="fa-solid fa-location-dot text-green-800"></i>&nbsp;
              Bali, Indonesia{" "}
            </p>
            <p className="text-black">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
              integer facilisis aliquet erat vitae viverra ornare. Placerat urna
              integer nibh justo. Dictum vulputate odio mauris amet, dictumst
              molestie. Faucibus consectetur mauris tristique dolor ut diam,
              adipiscing et. Semper mi proin malesuada orci phasellus.
              Consectetur posuere iaculis amet sem. Euismod turpis pellentesque
              sit risus eu, sagittis nulla. Facilisis urna, mi pharetra sed.
            </p>
            <p className="text-black">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
              integer facilisis aliquet erat vitae viverra ornare. Placerat urna
              integer nibh justo. Dictum vulputate odio mauris amet, dictumst
              molestie.{" "}
            </p>
            <div className=" lg:flex lg:items-center lg:justify-evenly sm:justify-between sm:h-auto">
              <div className="w-[345px] inline-block h-[285px] overflow-clip rounded-lg">
                <img
                  className="w-full h-full object-cover"
                  src="https://www.figma.com/file/f0nwwpNys96CpMUiajv1JS/image/2a49ac573f5dd99a65c9f4546bb21c07f247587d"
                  alt=""
                />
              </div>
              <div className="w-[345px] inline-block h-[285px] ml-7 overflow-clip rounded-lg">
                <img
                  className="w-full h-full object-cover"
                  src="https://www.figma.com/file/f0nwwpNys96CpMUiajv1JS/image/d1d636d1330ad53980abfc8e361c9064c7ce33d4"
                  alt=""
                />
              </div>
            </div>
            <p className="text-black mt-2 sm:text-sm">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet
              integer facilisis aliquet erat vitae viverra ornare. Placerat urna
              integer nibh justo. Dictum vulputate odio mauris amet, dictumst
              molestie. Faucibus consectetur mauris tristique dolor ut diam,
              adipiscing et. Semper mi proin malesuada orci phasellus.
              Consectetur posuere iaculis amet sem. Euismod turpis pellentesque
              sit risus eu, sagittis nulla. Facilisis urna, mi pharetra sed.
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
              nesciunt ea ad dolorum veritatis repudiandae non laudantium id,
              vero debitis eum voluptate architecto hic saepe iste, voluptas
              ducimus dolore nam. Lorem ipsum dolor, sit amet consectetur
              adipisicing elit. Tempore et, alias nostrum quae iste optio
              commodi laudantium libero vero quos iusto quidem molestiae eos a
              placeat eius beatae fugit aut. Lorem ipsum dolor sit, amet
              consectetur adipisicing elit. Hic libero quam officiis. Velit sed
              doloremque consequuntur nesciunt distinctio vero quisquam, nemo
              dolorum blanditiis ut facere porro error minima debitis esse.
            </p>
          </div>
          <div className="h-auto lg:w-[92%] lg:mx-auto lg:flex lg:flex-col lg:mt-10">
            <p className="text-black font-sans lg:self-center">
              Other Destinations
              <span className="ml-[170px] xs:ml-[100px]">
                <a className="text-[#43B97F] no-underline" href="#">
                  See all
                </a>
              </span>
            </p>
            <Map3 />
          </div>
        </div>
      </section>
      <div className="mt-36">
        <Footer />
      </div>
    </div>
  );
}

export default Discover;
