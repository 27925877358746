import Header from "./Header";
import Hero from "./Hero";
import Search from "./Search";
import Main from "./Main";

export default function Home() {
  return (
    <div>
      <section id="hero-sec">
        <Header />
        <Hero />
        <Search />
      </section>
      <section className="w-screen h-[1100px]">
        <Main />
      </section>
    </div>
  );
}
