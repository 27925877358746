function Hero() {
  return (
    <div className="w-[50%] mt-[190px] text-white mx-auto text-center font-[Poppins] sm:mt-[90px] sm:w-[70%]">
      <h1 className=" text-5xl drop-shadow-xl sm:text-2xl">Explore the world with hatred</h1>
      <p className=" drop-shadow-lg sm:text-sm">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorem
        ducimus optio mollitia suscipit laudantium. Perferendis dolores
        voluptatum expedita sint, ipsum quidem ipsam non officia est, itaque
        esse impedit maiores! Magni?
      </p>
    </div>
  );
}

export default Hero;
