export default [
  {
    img: "https://www.figma.com/file/f0nwwpNys96CpMUiajv1JS/image/a5463d799951b01521201a6cdc3a04c69b5d5a67",
    title: "Wakatobi beach is a paradise for coral reets in Indonesia",
    para: "Yogyakarta, Indonesia",
  },
  {
    img: "https://images.unsplash.com/photo-1615551043360-33de8b5f410c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D",
    title: "The Grand Canyon is a paradise for drought wildlife in USA",
    para: "The Grand Canyon, Arizona, USA",
  },
  {
    img: "https://media.istockphoto.com/id/514773355/photo/landscape-sunset-view-of-morain-lake-and-mountain-range.jpg?s=612x612&w=0&k=20&c=cgoEjmz16_8XqglLR-BprRBnT8s9glyLJBJXoTYuJmM=",
    title: "Banff Park is a paradise for animals wildlife in Canada",
    para: "Banff National Park, Alberta, Canada",
  },
  {
    img: "https://lp-cms-production.imgix.net/image_browser/turtle-lady-elliot-island-shutterstockRF.jpg",
    title: "The Great Barrien Reef is a paradise for coral reefs in Australia",
    para: "The Great Barrier Reef, Australia",
  },
];
