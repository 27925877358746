import "./index.css";

function Search() {
  function handleClick(){
    return(
      alert("You are broke... MOYE MOYE!!! XD!")
    );
  }

  return (
    <div className="grid grid-cols-3 divide-x divide-black w-[800px] h-[67px] bg-slate-100 rounded-xl mx-auto mt-[60px] shadow-xl text-center items-center sm:w-[90%] sm:h-auto sm:grid-cols-none sm:divide-y sm:grid-rows-3 ">
      <div className="flex justify-center">
        <img
          className="pr-2 mt-[1px]"
          src="./images/carbon-location-filled.svg"
        />
        <input
          id="user-input"
          className="bg-transparent w-[150px]"
          type="text"
          placeholder="City or Destination"
        />
      </div>
      <div>
        <input
          className="bg-transparent h-[40px]"
          type="date"
          name=""
          id=""
          placeholder="Date of Stay"
        />
      </div>
      <div className="flex justify-evenly">
        <div className="flex items-center">
          <label className="pr-2" htmlFor="#">
            Person
          </label>
          &nbsp;
          <select id="" className="bg-[#ece9e9] rounded-lg max-h-[35px]">
            <option value="1">1 (Self)</option>
            <option value="2">2 (Couple)</option>
            <option value="3">3 (Family)</option>
            <option value="4">4 (Family)</option>
          </select>
        </div>
        <button onClick={handleClick} className="flex relative w-[60px] h-[40px] items-center  rounded-xl bg-[#43B97F]"><img className="w-[100%] h-[80%] object-contain" src="./images/group-4.svg" alt="" /></button>
      </div>
    </div>
  );
}

export default Search;
