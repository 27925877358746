import React from "react";

interface DiscoverLProps {
  img: string;
  title: string;
  price: string;
}

const DiscoverL: React.FC<DiscoverLProps> = (props) => {
  return (
    <div className="shadow-md rounded-[10px] text-left hover:scale-105 ease-in-out duration-300 sm:h-[200px]">
      <div className="w-[97%] h-[225px] sm:h-[100px] ">
        <img
          className="h-[100%] w-[100%] object-contain"
          src={props.img}
          alt=""
        />
      </div>
      <p className="pl-3 pt-[20px] font-[Poppins] text-xl font-bold text-[#3D3E48] sm:text-sm sm:pt-[5px]">{props.title}</p>
      <p className="pl-3 font-[Poppins] text-base font-bold text-[#295943] xs:text-sm">{props.price}</p>
    </div>
  );
};

export default DiscoverL;
