import packagesData from "./Data2";
import Testicle from "./Testical";

export default function Map2() {
  const packageElements = packagesData.map((item, index) => (
    <Testicle
      key={index}
      img={item.img}
      title={item.title}
      para={item.para}
    />
  ));

  return (
    <div className="grid w-[95%] mt-[50px] mx-auto grid-cols-3 grid-rows-2 gap-[20px] h-[480px] ">
      {packageElements}
    </div>
  );
}
