import { Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';

export default function Navbar() {
  return (
    <nav className="flex items-center mt-3">
      <ul className="flex drop-shadow-lg sm:flex-wrap sm:justify-around sm:pl-0">
        <li className="pl-[20px] pr-[20px]">
          <Link
            to="/Home"
            className="no-underline text-white font-[Poppins] text-lg font-bold sm:text-sm"
          >
            Home
          </Link>
        </li>
        <li className="pl-[20px] pr-[20px]">
          <Link
            className="no-underline text-white font-[Poppins] text-lg font-bold sm:text-sm"
            to="/Discover"
          >
            Discover
          </Link>
        </li>
        <li className="pl-[20px] pr-[20px]">
          <Link
            className="no-underline text-white font-[Poppins] text-lg font-bold sm:text-sm"
            to="#"
          >
            Services
          </Link>
        </li>
        <li className="pl-[20px] pr-[20px]">
          <Link
            className="no-underline text-white font-[Poppins] text-lg font-bold sm:text-sm"
            to="#"
          >
            News
          </Link>
        </li>
        <li className="pl-[20px] pr-[20px]">
          <Link
            className="no-underline text-white font-[Poppins] text-lg font-bold sm:text-sm"
            to="/About"
          >
            About Us
          </Link>
        </li>
        <li className="pl-[20px] pr-[20px]">
          <Link
            className="no-underline text-white font-[Poppins] text-lg font-bold sm:text-sm"
            to="#"
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}
