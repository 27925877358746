import React, { useState, ChangeEvent } from "react";

const ImageChanger = () => {
  const [currentImage, setCurrentImage] = useState("./images/indonesia.svg");

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setCurrentImage(event.target.value);
  }; 

  return (
    <div className="flex">
      <div className="w-[20px] h-[20px] mt-[1px]">
        <img
          src={currentImage}
          alt="Current Image"
          className="w-[100%] h-[100%] object-contain"
        />
      </div>&nbsp;
      <select className="bg-transparent text-[#3D3E48] font=[Poppins] font-bold " onChange={handleChange} value={currentImage}>
        <option value="./images/indonesia.svg">ID</option>
        <option value="./images/nepal.png">NP</option>
        <option value="./images/india.png">IN</option>
        <option value="./images/canada.png">CA</option>
      </select>
    </div>
  );
};

export default ImageChanger;
