export default [
    {
        img: "./images/ellipse.png ",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis",
        title:
          "Monjulina Complex",
      },
    {
        img: "https://www.looper.com/img/gallery/the-untold-truth-of-voldemort/intro-1649393495.jpg",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis",
        title:
          "Voldemort Natalie",
      },
    {
        img: "https://www.thinkingfaith.org/sites/default/files/styles/article_full_687/public/field/image/Iron%20Man%203.jpg",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis",
        title:
          "Robert Downer Jr.",
      },
    {
        img: "https://wallpapercave.com/dwp2x/wp8839952.jpg",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis",
        title:
          "Annie Leonfart",
      },
    {
        img: "https://otakusinh.files.wordpress.com/2023/02/screenshot_20230202-121204-1.png?w=1024",
        para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis",
        title:
          "Perv Asshat",
      },
    {
      img: "https://w0.peakpx.com/wallpaper/306/530/HD-wallpaper-watch-the-eminence-in-shadow.jpg",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis",
      title:
        "Stylish Ruffian Slayer",
    },
  ];
  