import Footer from "./Footer";
import Map from "./Map";
import Map2 from "./Map2";

function Main() {
  return (
    <div className="mt-[100px] w-screen">
      <div className="flex justify-evenly">
        <div className="flex w-[45%]">
          <div className=" overflow-clip rounded-[95px] w-[270px] h-[445px] z-13">
            <img
              className="h-[100%] w-[100%] object-cover"
              src="./images/man1.png"
              alt="lul"
            />
          </div>
          <div className="self-end w-[200px] h-[330px] overflow-clip rounded-[95px] z-6 ml-[-30px] bg-white">
            <img
              className="rounded-[95px] relative right-[-15px] top-[-5px] w-[100%] h-[100%] object-cover z-4"
              src="./images/boat.png"
              alt=""
            />
          </div>
          <div className="ml-[-60px] w-[180px] h-[310px] overflow-clip rounded-[95px] bg-white z-10">
            <img
              className="relative right-[-10px] top-[-13px] rounded-[95px] w-[100%] h-[100%] object-cover z-5"
              src="./images/man2.png"
              alt=""
            />
          </div>
        </div>
        <div className="flex flex-col w-[45%] font-[Poppins] text-left">
          <p className=" p-[10px] font-bold text-[#295943] text-base">
            Travelers Point
          </p>
          <h2 className="p-[10px] font-bold text-[#3D3E48] text-[27px]">
            We help to find your dream place
          </h2>
          <p className="p-[10px] text-base text-[#3D3E48] ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel fringilla est ullamcorper eget nulla facilisi
          </p>
          <div className="pl-[10px] mt-[30px] flex flex-wrap justify-between w-[55%]">
            <h2 className="text-[#295943] font-bold text-[27px]">
              100+
              <p className="text-[#3D3E48] text-base font-normal">
                Holiday Package
              </p>
            </h2>
            <h2 className="text-[#295943] font-bold text-[27px]">
              172
              <p className="text-[#3D3E48] text-base font-normal">Hotels</p>
            </h2>
            <h2 className="text-[#295943] font-bold text-[27px]">
              68
              <p className="text-[#3D3E48] text-base font-normal">
                Elite Transportation
              </p>
            </h2>
            <h2 className="text-[#295943] font-bold text-[27px] mr-[-50px]">
              32M+
              <p className="text-[#3D3E48] text-base font-normal">
                Dream Place
              </p>
            </h2>
          </div>
        </div>
      </div>
      <div className="relative mt-[80px] font-[Poppins] text-xl h-[870px] text-center xs:h-[670px] sm:h-[650px]">
        <p className="text-[#295943] font-bold">Top Destination</p>
        <h2 className="text-[#3D3E48] font-bold text-[27px]">
          Discover your hatred
        </h2>
          <Map />
      </div>
      <div className="w-[95%] text-center mx-auto mt-4 h-[700px]">
        <p className="font-[Poppins] text-[#295943] text-base font-bold">Testimonial</p>
        <h2 className="font-[Poppins] text-3xl font-bold">What they say about us</h2>
        <Map2 />
      </div>
      <Footer />
    </div>
  );
}

export default Main;
