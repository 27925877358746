export default [
  {
    img: "./images/rectangle-5.png",
    price: "Rs. 150,000",
    title:
      "Taman Nasional Komodo",
  },
  {
    img: "./images/rectangle-6.png",
    price: "Rs. 145,000",
    title:
      "Taman Nasional Bunaken",
  },
  {
    img: "./images/rectangle-7.png",
    price: "Rs. 200,000",
    title:
      "Raja Ampat, Papua Barat",
  },
  {
    img: "./images/rectangle-8.png",
    price: "110,000",
    title:
      "Kepulauan Wakatobi",
  },
  {
    img: "./images/rectangle-9.png",
    price: "Rs 650,000",
    title:
      "Gili Trawangan, Lombok", 
  }, 
  {
    img: "./images/rectangle-10.png",
    price: "Rs 950,000",
    title:
      "Taman Nasional Bromo",
  },
];
