import packagesData from "./DataDiscover";
import DiscoverG from "./DiscoverG";

export default function Map3() {
  const packageElements = packagesData.map((item, index) => (
    <DiscoverG
      key={index}
      img={item.img}
      title={item.title}
      para={item.para}
    />
  ));

  return (
    <div className="grid grid-rows-4 w-[380px] gap-y-5 mr-10 lg:grid-rows-2 lg:grid-cols-2 lg:gap-x-5 lg:w-[90%] lg:mx-auto">
      {packageElements}
    </div>
  );
}
