import React from 'react';
import { BrowserRouter as Router, Route, Routes, RouteProps, Navigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Home from './Home';
import About from './About';
import Discover from './Discover';
import './transitions.css'; // Import your transitions CSS file

interface LocationProps {
  location?: any;
}

const App = () => {
  return (
    <Router>
      <div className='w-screen'>
        <Routes>
          <Route path='/' Component={Home}/>
          <Route
            path="/"
            element={<HomeWithTransition />}/>
          <Route path="/Home" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path='/Discover' element={<Discover />} />
        </Routes>
      </div>
    </Router>
  );
};

// Wrap Home component with CSSTransition
const HomeWithTransition: React.FC<LocationProps & RouteProps> = ({ location }) => {
  if (!location) {
    // Handle the case where location is not available
    return <Navigate to="/" />;
  }

  return (
    <CSSTransition key={location.key} classNames="fade" timeout={500}>
      <Home />
    </CSSTransition>
  );
};

export default App;
