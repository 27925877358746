function Footer() {
  return (
    <div className="w-screen">
      <footer className="h-[400px] w-[97%] rounded-xl mx-auto bg-[#295943] lg:h-auto">
        <div className="flex items-center lg:block ">
          <div className="relative font-[Poppins] text-white left-[50px] top-[40px] w-[280px] lg:w-[100%] lg:top-0 lg:left-0">
            <h2 className=" text-3xl">Moye Moye</h2>
            <p className="text-base mt-[20px]">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Iste
              consectetur hic delectus consequuntur optio incidunt ex sequi
              harum tempora, adipisci autem est possimus fuga ipsam, officiis ea
              necessitatibus quos beatae!
            </p>
          </div>
          <div className="flex relative justify-between text-white w-[800px] font-[Poppins] font-bold right-[-152px] top-9 flex-wrap lg:w-[100%] lg:right-0 lg:h-[280px] lg:overflow-scroll lg:top-0">
            <div>
              <h3 className="text-2xl font-[Poppins] lg:text-xl ">Links</h3>
              <nav className="flex">
                <ul className="font-[Poppins]">
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#">
                      Discover
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className=" no-underline text-white font-normal" href="#">
                      Special Deals
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline font-normal text-white" href="#">
                      Services
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#">
                      Community
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#">
                      About Us
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <h3 className="text-2xl font-[Poppins] lg:text-xl">Services</h3>
              <nav className="flex">
                <ul className="font-[Poppins]">
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#">
                      About Us
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className=" no-underline text-white font-normal" href="#">
                      Blod & Articles
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline font-normal text-white" href="#">
                      Terms & Conditions
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#"> 
                      Contact Us
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="lg:max-w-[30%] ">
              <h3 className="text-2xl font-[Poppins] lg:text-xl">Contact</h3>
              <nav className="flex">
                <ul className="font-[Poppins]">
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#">
                      Address: Mirazapur-5, KGF-3, No.6969A
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className=" no-underline text-white font-normal" href="#">
                      Why dis kolaveri?
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline font-normal text-white" href="#">
                      Phone: 981414221714
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs">
                    <a className="no-underline text-white font-normal" href="#">
                      Email: prettylilboi@proton.me
                    </a>
                  </li>
                  <li className="p-[5px] relative left-[-35px] lg:text-xs lg:p-0">
                    <a className="no-underline text-white font-normal" href="#">
                      Maps: Bajuwala, East & West is the best
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
      <div className="flex w-screen h-[50px] justify-center items-center">Coded with 💔🥹 by &nbsp; <a className="text-black" href="https://images.pexels.com/photos/208821/pexels-photo-208821.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" target="_blank">MrRayeen</a> </div>
    </div>
  );
}

export default Footer;
