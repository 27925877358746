import React from "react";

interface DiscoverGProps {
  img: string;
  title: string;
  para: string;
}

const DiscoverG: React.FC<DiscoverGProps> = (props) => {
  return (
    <div className="group h-[255px] rounded-xl overflow-clip hover:scale-110 duration-300 ease-in-out lg:hover:scale-100">
      <div className=" w-full h-full overflow-clip -z-1">
        <img
          className="h-[100%] w-[100%] object-cover"
          src={props.img}
          alt=""
        />
        <div className="z-10 bg-black opacity-30 relative top-[-255px] w-full h-full group-hover:opacity-0 duration-300 ease-in-out"></div>
      </div>
      <div className="relative top-[-220px] text-white z-20">
        <p className="pl-3 relative font-sans text-xl font-bold sm:text-sm xs:text-xs">
          {props.title}
        </p>
        <p className="pl-3 font-sans text-base font-bold relative xs:text-sm">
          {props.para}
        </p>
        <button className="bg-[#43B97F] px-2 py-2 relative ml-3 rounded-lg xs:px-1 xs:py-1">
          <a className="no-underline text-white font-sans sm:text-sm xs:text-xs" href="#">View more</a>
        </button>
      </div>
    </div>
  );
};

export default DiscoverG;
