import React from "react";

interface TesticalProps {
  img: string;
  title: string;
  para: string;
}

const Testical: React.FC<TesticalProps> = (props) => {
  return (
    <div className="group h-[210px] shadow-md rounded-[2px] text-left hover:bg-[#43B97F] text-white ease-in-out duration-300 sm:overflow-scroll">
      <div className="relative top-[10px] w-[50px] h-[50px] rounded-full ml-3 overflow-clip">
        <img
          className="h-[100%] w-[100%] object-cover"
          src={props.img}
          alt=""
        />
      </div>
      <p className="inline-block pl-3 pt-[20px] font-[Poppins] text-xl font-bold text-[#3D3E48] group-hover:text-white xs:text-sm">
        {props.title}
      </p>
      <p className="pl-3 font-[Poppins] text-base font-bold text-[#3D3E48] group-hover:text-white xs:text-xs">
        {props.para}
      </p>
    </div>
  );
};

export default Testical;
